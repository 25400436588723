import React from "react";

import Box from "@mui/material/Box";
import MUI_TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import ContentBox from "@components/ContentBox/ContentBox";
import searchIcon from "@assets/img/search_icon.svg";

import useSessionsTable from "./useSessionsTable";
import DashboardTabs from "../DashboardTabs";
import DesktopTable from "./DesktopTable";
import MobileTable from "./MobileTable";

const SessionsTable = props => {
  const {
    user,
    type,
    partner,
    isLaptopOrDesktop,
    onMessage,
    onShareOpen,
    loaded,
    tabs,
    fetchingRowContent,
    copyList,
    numCases,
    page,
    setPage,
    searchString,
    setSearchString,
    order,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    handleChangePage,
    handleRequestSort,
    requestSearch,
    handleEntryClick,    
    onAction,
    caseFilter,
    setCaseFilter,
    currentAction,
    resetPaginationAndFilters
  } = useSessionsTable(props);

  return (
    <ContentBox
      style={{
        display: "inline",
        maxHeight: "70vh",
        boxShadow: "none",
        margin: 0,
        padding: 0
      }}
    >
      <Box 
        sx={{
          width: "100%",
          alignItems: "flex-start"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {xs: "column", md: "row"},
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%"
          }}
        >
          <DashboardTabs 
            type="sessions" 
            translationGroup="dashboard_sessions"
            tabs={tabs}
            fetchingData={fetchingRowContent || !loaded}
            setPage={setPage}
            setSearchString={setSearchString}
            caseFilter={caseFilter}
            setCaseFilter={setCaseFilter}
          />
          <MUI_TextField
            size="small"
            placeholder="Sök..."
            type="search"
            onInput={e => requestSearch(e.target.value)}
            InputProps={{
              value: searchString,
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      paddingBottom: 0.25
                    }}
                    alt="logo"
                    src={searchIcon}
                  />
                </InputAdornment>
              ),
              sx: {
                px: 3,
                fontSize: "13px",
                borderRadius: "20px",
                width: "100%",
              }
            }}
            sx={{ 
              width: {xs: "100%", md: "30%"},
            }}
          />
        </Box>
        {isLaptopOrDesktop ? (
          <DesktopTable
            loaded={loaded}
            user={user}
            partner={partner}
            type={type}
            tabs={tabs}
            copyList={copyList}
            numCases={numCases}
            page={page}
            setPage={setPage}
            order={order}
            orderBy={orderBy}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            handleRequestSort={handleRequestSort}
            handleChangePage={handleChangePage}
            handleEntryClick={handleEntryClick}
            onAction={onAction}       
            onMessage={onMessage}
            onShareOpen={onShareOpen}
            currentAction={currentAction}
            resetPaginationAndFilters={resetPaginationAndFilters}
          />            
        ) : (
          <MobileTable
            loaded={loaded}
            user={user}
            partner={partner}
            type={type}
            tabs={tabs}
            copyList={copyList}
            numCases={numCases}
            page={page}
            setPage={setPage}
            order={order}
            orderBy={orderBy}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            handleRequestSort={handleRequestSort}
            handleChangePage={handleChangePage}
            handleEntryClick={handleEntryClick}
            onAction={onAction}       
            onMessage={onMessage}
            onShareOpen={onShareOpen}
            currentAction={currentAction}
            resetPaginationAndFilters={resetPaginationAndFilters}
          /> 
        )}
      </Box>
    </ContentBox>
  );
};

export default SessionsTable;