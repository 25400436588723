import React from "react";
import ContentBox from "@components/ContentBox/ContentBox";
import {createClassName} from "@helpers/utils";
import i18n from "@helpers/i18n";
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import api from "@helpers/api";
import { getCookie } from "@helpers/cookies";
import KycTable from "./KycTable";

/**
 * KYCs filled out or about to happen in the near future
 */
export default class DashboardKycs extends React.PureComponent
{
	state = _getInitialState.call(this);

	async componentDidMount()
	{
		this._isMounted = true;
		const userCaseList = await _getKycCaseList.call(this);
		this.setState({
			isLoading: false,
			userCaseList: userCaseList.batch,
      totalCases: parseInt(userCaseList.total)
		});
	}

	componentWillUnmount()
	{
		this._isMounted = false;
	}

  async componentDidUpdate(prevProps, prevState)
	{
    if (this.props.caseFilter.kyc !== prevProps.caseFilter.kyc) {
      this.setState({
        isLoadingCases: true
      });
      const userCaseList = await _getKycCaseList.call(this);
      this.setState({
        isLoadingCases: false,
        userCaseList: userCaseList.batch,
        totalCases: parseInt(userCaseList.total)
      });
    }
	}

	render()
	{
		const {props, state} = this;
		const {showHeader} = props;

		return (
			<>
				<ContentBox
         style={
          props.type === "customerModal" ? 
            { 
              maxHeight: "70vh",
              margin: 0,
              padding: 0,
              overflow: "auto",
              boxShadow: "none"
            }
          :
            {}
          }
        >
          {showHeader && (
            <h2>
              {i18n("dashboard_selector", "kyc")}
            </h2>
          )}
					{state.isLoading && (
						<div style={{padding: "91px 0"}}>
							<ActivityIndicator color="#4cb7d5" busy/>
						</div>
					)}

					{!state.isLoading && (
            <>
              <div 
                className="dashboard-cases"             
                style={
                  props.type === "customerModal" ? 
                    { 
                      minHeight: "unset"
                    }
                  :
                    {}
                }
              >
                <KycTable
                  data={state.userCaseList}
                  totalCases={state.totalCases}
                  isLoadingCases={state.isLoadingCases || false}
                  currentAction={state.currentAction}
                  onAction={this._onRowAction}
                  caseFilter={props.caseFilter} 
                  setCaseFilter={props.setCaseFilter}
                  getCases={_getKycCaseList.bind(this)}
                />
                {state.isDisabled && (
                  <div className="dashboard-cases-disabled">
                    <ActivityIndicator color="#4cb7d5" busy/>
                  </div>
                )}
              </div>
            </>
					)}
				</ContentBox>
			</>
		);
	}

	_onRowAction = async (action, ssn) =>
	{
    this.setState({
      currentAction: action
    });

		if (action === "download")
		{
			let {caseId} = await api("/case/kyc-status", {
				identity: ssn,
				wl: "fair-kyc"
			});

			const rawDataInBase64 = await api("/case/downloadReceipt", {
				caseId: caseId,
				userId: this.props.user.ssn,
				partnerId: this.props.advisor.ssn
			}, {}, true);

			if (rawDataInBase64 !== "")
			{
				console.log("Downloading case data...");
				// It is necessary to create a new blob object with mime-type explicitly set
				// otherwise only Chrome works like it should
				//blob = atob(blob);

				// For other browsers:
				// Create a link pointing to the ObjectURL containing the blob.
				const link = document.createElement('a');
				link.href = "data:application/pdf;base64," + rawDataInBase64;
				link.download = caseId + ".pdf";
				link.click();
			}
			else
			{
				console.log("Could not download case data");
			}      

      this.setState({
        currentAction: 'none',
			});
		}
	};
}

function _getInitialState()
{
	return {
		tabSelected: "attention",
		isLoading: true,
    isLoadingCases: false,
    currentAction: 'none',
		isDisabled: false,
		isMounted: false,
		kycCaseList: []
	};
}

function _getKycCaseList(page, searchString, order, orderBy)
{
	const ssn = this.props.user.ssn;

  // check if we have any options saved..
  const cobOptions = JSON.parse(getCookie("cobOptions")) || null;
  // .. and set rowsPerPage
  const rowsPerPage= cobOptions?.rowsPerPage["kyc"] || 10;

	return new Promise(async (resolve, reject) =>
	{
		try
		{
			const cases = await api("/kyc/list", {
        ssn: ssn,
        filter: this.props.caseFilter.kyc,
        page: page || 0,
        order: order || "asc",
        orderBy: orderBy || "kyc_date",
        searchString: searchString || "",
        limit: rowsPerPage === -1 ? 100 : rowsPerPage
      });
			if (this._isMounted)
			{
				resolve(cases);
			}
		}
		catch (e)
		{
			console.error(e.message);
			reject();
		}
	});
}