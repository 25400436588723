import React from "react";
import ContentBox from "@components/ContentBox/ContentBox";
import i18n from "@helpers/i18n";
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import api from "@helpers/api";
import { getCookie } from "@helpers/cookies";

import SessionsTable from "./SessionsTable";
import Modal from "@components/Modal/Modal";

/**
 * Mina rådgivningar
 */
export default class DashboardSessions extends React.PureComponent
{

	state = _getInitialState.call(this);

	async componentDidMount()
	{
		this._isMounted = true;
		const userCaseList = await _getUserSessions.call(this);
		this.setState({
			isLoading: false,
			userCaseList: userCaseList.batch,
      totalCases: parseInt(userCaseList.total)
		});
	}

	componentWillUnmount()
	{
		this._isMounted = false;
	}

  async componentDidUpdate(prevProps, prevState)
	{
    if (this.props.caseFilter.sessions !== prevProps.caseFilter.sessions) {
      this.setState({
        isLoadingCases: true
      });
      const userCaseList = await _getUserSessions.call(this);
      this.setState({
        isLoadingCases: false,
        userCaseList: userCaseList.batch,
        totalCases: parseInt(userCaseList.total)
      });
    }
	}

	render()
	{
		const {props, state} = this;
		const {user, advisor, partner, faAuth, showHeader} = props;

		return (
			<>
				<ContentBox
         style={
          props.type === "customerModal" ? 
            { 
              height: "70vh",
              margin: 0,
              padding: 0,
              overflow: "auto",
              boxShadow: "none"
            }
          :
            {}
          }
        >
          {showHeader && (
            <h2>
              {i18n("dashboard_selector", "sessions")}
            </h2>
          )}
          {state.isLoading && (
						<div style={{padding: "91px 0"}}>
							<ActivityIndicator color="#4cb7d5" busy/>
						</div>
					)}

					{!state.isLoading && (
            <>
              <div
                className="dashboard-cases"
                style={
                  props.type === "customerModal" ? 
                    { 
                      minHeight: "unset"
                    }
                  :
                    {}
                }
              >
                <SessionsTable
                  data={state.userCaseList}
                  totalCases={state.totalCases}
                  isLoadingCases={state.isLoadingCases || false}
                  currentAction={state.currentAction}
                  user={user}
                  type="main"
                  partner={partner}
                  faAuth={faAuth}
                  showHeader={showHeader}
                  caseFilter={props.caseFilter} 
                  setCaseFilter={props.setCaseFilter}
                  onShareOpen={props.onShareOpen}
                  getCases={_getUserSessions.bind(this)}
                  onAction={this._onRowAction}
                  onMessage={this._onRowMsg}
                />
              </div>
            </>
					)}
				</ContentBox>

				<Modal visible={state.isReminderModalVisible}>
					<p>{i18n("dashboard_sessions", "reminder_to_customer")}</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideReminderModal}/>
					</Modal.ButtonsContainer>
				</Modal>

				<Modal visible={state.isRecallModalVisible}>
					<p>{i18n("dashboard_sessions", "case_revoked")}</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideRecallModal}/>
					</Modal.ButtonsContainer>
				</Modal>

				<Modal visible={state.isDeleteModalVisible}>
					<p>{i18n("dashboard_sessions", "draft_removed")}</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideDeleteModal}/>
					</Modal.ButtonsContainer>
				</Modal>

				<Modal visible={state.isProcessingModalVisible}>
					<p>{i18n("dashboard_sessions", "case_moved_in_progress")}</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideProcessingModal}/>
					</Modal.ButtonsContainer>
				</Modal>

				<Modal visible={state.isSignedByBoVisible}>
					<p>{state.signedByBoMessage}</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideSignedByBo}/>
					</Modal.ButtonsContainer>
				</Modal>

				<Modal visible={state.isProcessingUnsetModalVisible}>
					<p>{i18n("dashboard_sessions", "case_moved_sent")}</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideProcessingUnsetModal}/>
					</Modal.ButtonsContainer>
				</Modal>

				<Modal visible={state.isMessageModalVisible}>
					<p>{i18n("dashboard_sessions", "message_saved")}</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideMsgModal}/>
					</Modal.ButtonsContainer>
				</Modal>
			</>
		);
	}

	_onRowMsg = async (caseId, title, text) =>
	{
		const {user} = this.props;

		const msg = await api("/case/message", {
			caseId: caseId,
			partnerId: user.ssn,
			title: title,
			text: text
		});

		this.state.userCaseList.forEach(function (userCase)
		{
			if (userCase.caseId === caseId)
			{
				userCase.messages.unshift(msg);
			}
		});

		this.setState({
			isMessageModalVisible: true,
			userCaseList: this.state.userCaseList
		});
	};

	_onRowAction = async (action, caseId, ssn, callbackFunc) =>
	{
		const {type, user, advisor, cobForm, customerState, setCustomerState} = this.props;    

    this.setState({
      currentAction: action
    });

		if (action === 'remind')
		{
			api("/case/reminder", {
				caseId: caseId,
				userId: user.ssn,
				partnerId: advisor.ssn
			});

			this.setState({
        currentAction: 'none',
				isReminderModalVisible: true
			});
			return;
		}

		if (action === 'sign_bo')
		{
			try
			{
        this.setState({
          isLoadingCases: true
        });

				const result = await api("/case/sign_bo", {
					caseId: caseId,
					userId: user.ssn,
					partnerId: advisor.ssn
				});

        const userCaseList = await _getUserSessions.call(this);
				this.setState({
          isLoadingCases: false,
          currentAction: 'none',
					isSignedByBoVisible: true,
					userCaseList: userCaseList.batch,
					signedByBoMessage: result
				});
			}
			catch (error)
			{
				this.setState({
          isLoadingCases: false,
          currentAction: 'none',
					isSignedByBoVisible: true,
					signedByBoMessage: error.message
				});
			}
      
      if (callbackFunc) callbackFunc();

			return;
		}

		if (action === 'processing')
		{
      this.setState({
        isLoadingCases: true
      });
			api("/case/processing", {
				caseId: caseId,
				userId: user.ssn,
				partnerId: advisor.ssn
			});
      
      const userCaseList = await _getUserSessions.call(this);
			this.setState({
        isLoadingCases: false,
        currentAction: 'none',
				isProcessingModalVisible: true,
        userCaseList: userCaseList.batch,
			});
      
      if (callbackFunc) callbackFunc();

			return;
		}

		if (action === 'unprocessing')
		{
      this.setState({
        isLoadingCases: true
      });
			api("/case/unprocessing", {
				caseId: caseId,
				userId: user.ssn,
				partnerId: advisor.ssn
			});
      
      const userCaseList = await _getUserSessions.call(this);
      this.setState({
        isLoadingCases: false,
        currentAction: 'none',
				isProcessingUnsetModalVisible: true,
        userCaseList: userCaseList.batch,
			});
      
      if (callbackFunc) callbackFunc();

			return;
		}

		if (action === 'delete')
		{
      this.setState({
        isLoadingCases: true
      });
			api("/case/delete", {
				caseId: caseId,
				userId: user.ssn,
				partnerId: advisor.ssn
			});

      const userCaseList = this.state.userCaseList.filter(userCase => userCase.caseId !== caseId);
			this.setState({
        isLoadingCases: false,
        currentAction: 'none',
				isDeleteModalVisible: true,
        userCaseList
			});

			return;
		}

		if (action === 'recall')
		{
      this.setState({
        isLoadingCases: true
      });

			api("/case/recall", {
				caseId: caseId,
				userId: user.ssn,
				partnerId: advisor.ssn
			});

      const userCaseList = await _getUserSessions.call(this);
			this.setState({
        isLoadingCases: false,
        currentAction: 'none',
				isRecallModalVisible: true,
				userCaseList: userCaseList.batch
			});

      if (callbackFunc) callbackFunc();

			return;
		}

		if (action === 'download')
		{
			const rawDataInBase64 = await api("/case/downloadReceipt", {
				caseId: caseId,
				userId: user.ssn,
				partnerId: advisor.ssn
			}, {}, true);

			if (rawDataInBase64 !== "")
			{
				this.downloadFile(caseId + ".pdf", rawDataInBase64);
			}

      this.setState({
        currentAction: 'none',
			});

			return;
		}

    // action === 'continue'
    // action === 'copy'
    if (type === "customerModal") {      
      document.documentElement.style.overflow = "auto"; // re-enable main page scroll
    }

    setCustomerState({
      ...customerState,
      selectedCaseId: caseId,
      ssnOrg: ssn.replaceAll("-", ""),
    }, () =>
    {
        cobForm.current._onSubmit();
    });
	};

	_onHideReminderModal = () =>
	{
		this.setState({
			isReminderModalVisible: false
		});
	};

	_onHideRecallModal = () =>
	{
		this.setState({
			isRecallModalVisible: false
		});
	};

	_onHideDeleteModal = () =>
	{
		this.setState({
			isDeleteModalVisible: false
		});
	};

	_onHideProcessingModal = () =>
	{
		this.setState({
			isProcessingModalVisible: false
		});
	};

	_onHideSignedByBo = () =>
	{
		this.setState({
			isSignedByBoVisible: false
		});
	};

	_onHideProcessingUnsetModal = () =>
	{
		this.setState({
			isProcessingUnsetModalVisible: false
		});
	};

	_onHideMsgModal = () =>
	{
		this.setState({
			isMessageModalVisible: false
		});
	};

	downloadFile(filename, base64pdf)
	{
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		//blob = atob(blob);

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const link = document.createElement('a');
		link.href = "data:application/pdf;base64," + base64pdf;
		link.download = filename;
		link.click();
	}
}

function _getInitialState()
{
	return {
		caseFilter: "all",
		isLoading: true,
		isLoadingCases: false,
    currentAction: 'none',
		isDisabled: false,
		isMounted: false,
		isReminderModalVisible: false,
		isRecallModalVisible: false,
		isDeleteModalVisible: false,
		isProcessingModalVisible: false,
		isProcessingUnsetModalVisible: false,
		isMessageModalVisible: false,
		isSignedByBoVisible: false,
		signedByBoMessage: "",
		userCaseList: []
	};
}

function _getUserSessions(page, searchString, order, orderBy)
{
	const ssn = this.props.user.ssn;

	// check if we have any options saved..
	const cobOptions = JSON.parse(getCookie("cobOptions")) || null;
	// .. and set rowsPerPage
	const rowsPerPage = cobOptions?.rowsPerPage["sessions"] || 10;

	return new Promise(async (resolve, reject) =>
	{
		try
		{
			console.log("Loading case list...");
			let cases = await api("/case/list", {
				ssn: ssn,
				mode: "sessions",
				filter: this.props.caseFilter.sessions,
				page: page || 0,
				order: order || "desc",
				orderBy: orderBy || "changed",
				searchString: searchString || "",
				limit: rowsPerPage === -1 ? 100 : rowsPerPage
			});

			if (this._isMounted)
			{
				resolve(cases);
			}
		}
		catch (e)
		{
			console.error(e.message);
			reject();
		}
	});
}