import { useState, useEffect } from "react";

// HOOKS
import useResponsive from "@hooks/useResponsive";

const useCustomerModal = props => {
  const { partner, visible, advisor, client, isSession, onClose, updateCustomers, caseFilterContext } = props;
  const [currentTab, setCurrentTab] = useState("overview");
  const [isVisible, setIsVisible] = useState(visible);
  const isMobileOrTablet = useResponsive("down", "md");
  
  const handleTabChange = (e, newValue) => {
    if (caseFilterContext) caseFilterContext.setCaseFilter(null); // reset case filter (force using default tab)
    setCurrentTab(newValue);
  };

  const handleClose = () => {
    setIsVisible(false);
    if (onClose) {
      onClose();      
      document.documentElement.style.overflow = "auto";
    }
  };

  // check if props changed
  useEffect(() => {
    setIsVisible(visible);
  }, [visible])
  

  return {
    isMobileOrTablet,
    isVisible,
    partner,
    advisor,
    client,
    currentTab,
    isSession,
    handleClose,
    handleTabChange,
    updateCustomers,
    caseFilterContext
  };
};

export default useCustomerModal;