// MODULES
import React, { useState } from "react";
import moment from "moment";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";

// HELPERS
import i18n from "@helpers/i18n";
import api from "@helpers/api";

const ContactDetails = props => {
  const { client, updateCustomers } = props;
  const [spar, setSpar] = useState({
    currentAddress: {
      street: client.street,
      postal_code: client.postal_code,
      city: client.city,
      lastUpdated: client.details_lookup ? moment(client.details_lookup).format("YYYY-MM-DD") : "-",
    },
    isChecking: false,
    needsUpdate: null,
    isUpdating: false,
    updated: false,
    data: null
  });

  const handleSparLookup = async () => {    
    const lookupValue = client.id;
    // const lookupKey = this.isCompany || lookupValue.substr(0, 2) === "16" ? "orgNumber" : "ssn";
    const lookupKey = client.type === "company" ? "orgNumber" : "ssn";
    try
    {
      setSpar(prevState => ({
        ...prevState,
        needsUpdate: null,
        isChecking: true,
        updated: false
      }));
      const lookupResult = await api("/lookup", {[lookupKey]: lookupValue});      

      if (
        lookupResult.street && lookupResult.street !== lookupResult.client.street ||
        lookupResult.zipCode && lookupResult.zipCode !== lookupResult.client.postal_code || lookupResult.city && lookupResult.city !== lookupResult.client.city
        ){
        setSpar(prevState => ({
          ...prevState,
          isChecking: false,
          needsUpdate: true,
          data: lookupResult
        }));
      }
      else {
        setSpar(prevState => ({
          ...prevState,
          isChecking: false,
          needsUpdate: false
        }));
      }
    }
    catch (e)
    {
      console.error(e.message);
    }
  }

  const handleAddressUpdate = async () => {
    const addressData = {
      street: spar.data.street,
      postal_code: spar.data.zipCode,
      city: spar.data.city,
    }

    try
    {
      setSpar(prevState => ({
        ...prevState,
        isUpdating: true
      }));
      const updatedCustomer = await api("/lookup-update", { clientId: client.id, addressDetails: addressData});

      updateCustomers && updateCustomers(updatedCustomer); // update the customers table data
      addressData.lastUpdated = moment(updatedCustomer.details_lookup).format("YYYY-MM-DD");
      
      setSpar(prevState => ({
        ...prevState,
        currentAddress: addressData,
        needsUpdate: null,
        isUpdating: false,
        updated: true,
      }));
    }
    catch (e)
    {
      console.error(e.message);
    }
  }

  return (    
    <>
      <Box sx={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        // mt: 2, 
        padding: 2,
      }}>
        <Box sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: 0.6
        }}>
          <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
            <b>Epost:</b>
          </Typography>
          <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
            <Link
            href={`mailto:${client.email}`}
            >
              {client.email}
            </Link>                                
          </Typography>
        </Box>
        <Box sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: 0.6
        }}>
          <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
            <b>Telefon:</b>
          </Typography>                                
          <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
            {client.phone}
          </Typography>
        </Box>
        <Box sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: 0.6
        }}>
          <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
            <b>Telefon:</b>
          </Typography>                                
          <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
            {client.phone_alternative}
          </Typography>
        </Box>
        <Box sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: 0.6
        }}>
          <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
            <b>Adress</b>
          </Typography>                                
        </Box>
        <Box sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: 0.6
        }}>
          <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
            {spar.currentAddress.street} <br/>
            {spar.currentAddress.postal_code} {spar.currentAddress.city}
          </Typography>                       
        </Box>
        <Box sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: 2.5
        }}>
          <Box sx={{ width: "45%"}}>
            <Button
              key="SPAR"
              label="Hämta från SPAR"
              appearance="secondary"
              disabled={spar.isChecking}
              className="Cob-Footer-button"
              busy={spar.isChecking}
              filled
              fullWidth
              onClick={handleSparLookup}
            />            
          </Box>
          <Box sx={{ width: "45%"}}>
            <Typography>{i18n("spar", "last_updated")} (SPAR) {spar.currentAddress.lastUpdated}</Typography>
          </Box>
        </Box>
        {spar.needsUpdate === false && (
          <Box sx={{ alignItem: "flex-start", mt: 2, ml: 1, color: "green"}}>
            <Typography>{i18n("spar", "details_up_to_date")}.</Typography>
          </Box>        
        )}
        {spar.updated && (
          <Box sx={{ alignItem: "flex-start", mt: 2, ml: 1, color: "green"}}>
            <Typography>{i18n("spar", "details_updated")}.</Typography>
          </Box>        
        )}
      </Box>
      {spar.needsUpdate && (
        <Modal 
          visible={spar.needsUpdate}
          title={i18n("spar", "modal_title")}
        > 
          {spar.isUpdating ? (
            <Box sx={{ width: "100%", alignItems: "center"}}>
              <ActivityIndicator className="App-ActivityIndicator" color="#003d50" busy/>            
            </Box>
          ):(
            <>
              <Box sx={{ alignItems: "flex-start" }}>
                <Typography><b>{i18n("spar", "address_found")}:</b></Typography>
                <Typography>{spar.data.street}</Typography>
                <Typography>{spar.data.zipCode} {spar.data.city}</Typography>
                <br />
                <Typography><b>{i18n("spar", "address_current")}:</b></Typography>
                <Typography>{spar.data.client.street}</Typography>
                <Typography>{spar.data.client.postal_code} {client.city}</Typography>
                <br />
                <Typography>{i18n("spar", "update_details")}?</Typography>
              </Box>
              <Box 
                style={{ 
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: '20px'
                }}>
                <Modal.Button
                  label={i18n("general", "no")}
                  appearance="primary"
                  onClick={() => setSpar(prevState => ({
                    ...prevState,
                    needsUpdate: null
                  }))}
                />

                <Modal.Button
                  label={i18n("general", "yes")}
                  appearance="secondary"
                  onClick={handleAddressUpdate}
                  filled
                />
              </Box>
            </>
          )}
        </Modal>     
      )}
    </>
  );
};

export default ContactDetails;