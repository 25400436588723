import React from "react";
import i18n from "@helpers/i18n";

export default class DashboardSelector extends React.PureComponent {
	render() {

		const {onToggle, dashboardSelected, modules, user} = this.props;

		const hasAnyListPermissions = user.hasPermission("case.list") ||
			user.hasPermission("fmk.list") ||
			user.hasPermission("kyc.list") ||
			user.hasPermission("futur.list");

		if (!hasAnyListPermissions)
		{
			return null;
		}

		return (
			<div className="dashboard-selector">
				<ul>
					<li className={dashboardSelected === "start" ? "selected" : ""} onClick={() => {
						if (dashboardSelected !== "start") {
							onToggle("start");
						}
					}}>
						{i18n("dashboard_selector", "start")}
					</li>

					{user.hasPermission("case.list") && modules.includes("dashboard") && (
						<li className={dashboardSelected === "sessions" ? "selected" : ""} onClick={() => {
							if (dashboardSelected !== "sessions") {
								onToggle("sessions");
							}
						}}>
              				{i18n("dashboard_selector", dashboardSelected)}
						</li>
					)}

					{user.hasPermission("futur.list") && modules.includes("dashboardFutur") && (
						<li className={dashboardSelected === "futur" ? "selected" : ""} onClick={() => {
							if (dashboardSelected !== "futur") {
								onToggle("futur");
							}
						}}>
							{i18n("dashboard_selector", dashboardSelected)}
						</li>
					)}

					{user.hasPermission("kyc.list") && modules.includes("dashboardKycs") && (
						<li className={dashboardSelected === "kyc" ? "selected" : ""} onClick={() => {
							if (dashboardSelected !== "kyc") {
								onToggle("kyc");
							}
						}}>
							{i18n("dashboard_selector", dashboardSelected)}
						</li>
					)}

					{user.hasPermission("case.list") && modules.includes("dashboard") && (
						<li className={dashboardSelected === "other" ? "selected" : ""} onClick={() => {
							if (dashboardSelected !== "other") {
								onToggle("other");
							}
						}}>
              				{i18n("dashboard_selector", dashboardSelected)}
						</li>
					)}

					{user.hasPermission("fmk.list") && modules.includes("dashboardPoas") && (
						<li className={dashboardSelected === "poas" ? "selected" : ""} onClick={() => {
							if (dashboardSelected !== "poas") {
								onToggle("poas");
							}
						}}>
              				{i18n("dashboard_selector", dashboardSelected)}
						</li>
					)}
				</ul>
			</div>
		);
	}
}