import React from "react";

// COMPONENTS
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";

// HELPERS
import * as utils from "@helpers/utils";


export default class Button extends React.PureComponent {
    click() {
        this.refs.button.click();
    }

    render() {
        const { props } = this;
        const { style, label, busy, size, type, filled, appearance, disabled, fullWidth, onClick, dataInteractive } = props;
        const classes = utils.createClassName(props.className, {
            "Button": true,
            "full-width": fullWidth === true,
            "busy": busy === true,
            "small": size === "small",
            "filled": filled === true,
            "primary": appearance === "primary",
            "secondary": appearance === "secondary",
            "medium": appearance === "medium",
            "dark": appearance === "dark",
            "success": appearance === "success",
            "warning": appearance === "warning",
            "danger": appearance === "danger",
            "white": appearance === "white",
        });

        return (
            <button
                ref="button"
                style={ style }
                className={ classes }
                type={ type || "button" }
                disabled={ disabled }
                onClick={ onClick }
                data-interactive={dataInteractive || ""}
            >
                <div className="Button-container">
                    <ActivityIndicator busy={ busy } />
                    <div className="Button-label">{ label || "" }</div>
                </div>
            </button>
        );
    }
}