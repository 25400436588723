// MODULES
import React from "react";
import PropTypes from "prop-types";

import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// COMPONENTS
import Modal from "@components/Modal/Modal";
import ContactDetailsCard from "./ContactDetailsCard/ContactDetailsCard";
import UploadAndSignCard from "./UploadAndSignCard/UploadAndSignCard";
import BankDetailsCard from "./BankDetailsCard/BankDetailsCard";

import DashboardSessions from "../../views/Dashboard/DashboardSessions";
import DashboardPoas from "../../views/Dashboard/DashboardPoas";
import DashboardFutur from "../../views/Dashboard/DashboardFutur";
import DashboardKycs from "../../views/Dashboard/DashboardKycs";

// HELPERS
// import i18n from "@helpers/i18n";

import useCustomerModal from "./useCustomerModal";
import LogsTable from "./LogsTable";

const CustomerModal = props => {
  const { partner, isMobileOrTablet, isVisible, advisor, client, currentTab, isSession, handleClose, handleTabChange, updateCustomers, caseFilterContext } = useCustomerModal(props);

  return (
    <Modal
      className="Cob-CustomersTable-CustomerModal"
      visible={isVisible}
      title={client.name}
      subtitle={`${client.type === "person" ? "Persnr." : "Orgnr."}: ${client.id}`}
    >
      <CloseIcon
        onClick={handleClose} 
        sx={{
          position: "absolute",
          top: "15px",
          right: "15px",
          height: "32px",
          width: "32px",
          color: "#FFF",
          backgroundColor: "grey.400",
          borderRadius: "50%",
          padding: "5px",
          cursor: "pointer"
        }} 
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
          marginBottom: {xs: 2, md: 4},
          borderBottom: "5px solid #003d50",
        }}
      >
        <Tabs
          variant="scrollable"
          aria-label="customer tabs"
          value={currentTab}
          onChange={handleTabChange}
          scrollButtons={false}
          // allowScrollButtonsMobile
          sx={{            
            minHeight: "unset",
            "& .MuiTabs-flexContainer": {
              flexDirection: "row",
            },
            "& .MuiButtonBase-root": {
              fontFamily: "Roboto",
              fontSize: "16px",
              minHeight: "unset",
              py: 0.625,
              px: 2.25,
              color: "#FFF",
              backgroundColor: "#003d50",
              border: 0,
              borderBottom: "5px solid #003d50",
              // transform: "skew(10deg)" /* Equal to skewX(10deg) */
            },
            "& .MuiTabs-scrollButtons.Mui-disabled": {
              opacity: 0.3
            },
            "& .Mui-selected": {
              // color: "#FFF !important",
              color: "#003d50 !important",
              // backgroundColor: "#003d50",
              backgroundColor: "#FFF",
              border: "3px solid #003d50",
              borderBottom: "4px solid #003d50",
            },
            "& .Mui-disabled": {
              backgroundColor: "rgba(0, 63, 82, 0.25)",
              borderBottom: "rgba(0, 63, 82, 0.25)",
              cursor: "not-allowed !important",
              pointerEvents: "auto !important",
            },
            "& .MuiTabs-indicator": {
              display: "none"
            },
            "& .MuiTabs-scroller": {
              alignSelf: "flex-start",
              width: "-webkit-fill-available"
            },
          }}
        >
          <Tab
            disableRipple
            disableFocusRipple
            label="Översikt"
            value="overview"
            sx={{ alignItems: "flex-start", px: 0, mr: 0.5, minWidth: 60 }}
          />
          <Tab
            disableRipple
            disableFocusRipple
            label="Rådgivningar"
            value="consultations"
            sx={{ alignItems: "flex-start", px: 0, mr: 0.5, minWidth: 60 }}
            disabled={isSession}
          />
          <Tab
            disableRipple
            disableFocusRipple
            label="Fullmaktskollen"
            value="poa"
            sx={{ alignItems: "flex-start", px: 0, mr: 0.5, minWidth: 60 }}
            disabled={isSession}
          />
          <Tab
            disableRipple
            disableFocusRipple
            label="Futur"
            value="futur"
            sx={{ alignItems: "flex-start", px: 0, mr: 0.5, minWidth: 60 }}
            disabled={isSession}
          />
          <Tab
            disableRipple
            disableFocusRipple
            label="KYC"
            value="kyc"
            sx={{ alignItems: "flex-start", px: 0, mr: 0.5, minWidth: 60 }}
            disabled={isSession}
          />
          <Tab
            disableRipple
            disableFocusRipple
            label="Aktivitets logg"
            value="log"
            sx={{ alignItems: "flex-start", px: 0, mr: 0.5, minWidth: 60 }}
          />
        </Tabs>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: isMobileOrTablet ? "column" : "row",
          alignItems: "flex-start",
        }}
      >
        {currentTab === "overview" && (
          <>
            {partner?.customerModalWidgets?.includes("ContactDetails") && (
              <ContactDetailsCard client={client} updateCustomers={updateCustomers} />
            )}
            {partner?.customerModalWidgets?.includes("UploadAndSign") && (
              <UploadAndSignCard advisor={advisor} client={client} />
            )}
            {partner?.customerModalWidgets?.includes("BankDetails") && (
              <BankDetailsCard advisor={advisor} client={client} standaloneApplications={partner.standaloneApplications} />
            )}
          </>
        )}
        {currentTab === "consultations" && (
          <DashboardSessions
            type="customerModal"
            user={advisor}
            advisor={advisor}
            partner={partner}
            client={client}
            cobForm={caseFilterContext.cobForm}
            caseFilter={caseFilterContext.caseFilter || 'draft'}
            setCaseFilter={caseFilterContext.setCaseFilter}
          />
        )}
        {currentTab === "poa" && (
          <DashboardPoas
            type="customerModal"
            user={advisor}
            advisor={advisor}
            partner={partner}
            client={client}
            cobForm={caseFilterContext.cobForm}
            caseFilter={caseFilterContext.caseFilter || 'sent'}
            setCaseFilter={caseFilterContext.setCaseFilter}
          />
        )}
        {currentTab === "futur" && (
          <DashboardFutur
            type="customerModal"
            user={advisor}
            advisor={advisor}
            partner={partner}
            client={client}
            cobForm={caseFilterContext.cobForm}
            caseFilter={caseFilterContext.caseFilter || 'sent'}
            setCaseFilter={caseFilterContext.setCaseFilter}
          />
        )}
        {currentTab === "kyc" && (
          <DashboardKycs
            type="customerModal"
            user={advisor}
            advisor={advisor}
            partner={partner}
            client={client}
            cobForm={caseFilterContext.cobForm}
            caseFilter={caseFilterContext.caseFilter || 'expired'}
            setCaseFilter={caseFilterContext.setCaseFilter}
          />
        )}
        {currentTab === "log" && (
          <LogsTable 
            client={client}
          />
        )}
      </Box>
      <Box sx={{position: 'absolute', right: "30px", bottom: "30px"}}>
        {/* <Modal.Button
          key="quit-customer-modal"
          label="Stänga"
          appearance="primary"
          // disabled={busy}
          onClick={onClose}
        /> */}
        {/* <Modal.Button
          key="start-consultation-1"
          label="Start Consultation"
          appearance="secondary"
          // disabled={busy}
          // onClick={this._onOpenSingleSignPage}
          type="submit"
          filled
        /> */}
        {/* <Modal.Button
          key="start-consultation-2"
          label="Start Type 2Consultation"
          appearance="primary"
          // disabled={busy}
          // onClick={this._onOpenSingleSignPage}
          filled
          disabled
        /> */}
      </Box>
    </Modal>
  );
};

export default CustomerModal;